.card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.329);
    margin: 20px !important;
    border-radius: 10px !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.card-deck {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.btn {
    border-radius: 50px !important;
}

.payment-btn {
    padding: 0.5rem 1rem !important;
    outline: none;
    color: white;
    background: #ffffff;
}

payment-btn:hover {
    color: white;
    background: #ffffff;
}

.contact-col {
    padding-left: 0px !important;
    margin-left: -5px !important;
    padding-right: 15px !important;
}

.billing-col {
    padding-left: 15px !important;
    padding-right: 5px !important;
}

@media only screen and (max-width: 768px){
    .contact-col {
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-left: 0px !important;
    }
    
    .billing-col {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.graph-choice {
    max-width: 250px;
    margin-top: 15px;
}