// Here you can add other styles

.btn-primary.disabled, .btn-primary:disabled {
  color: white;
  background-color: grey;
  border-color: grey;
  opacity: 0.35;
}



.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
   background-color: grey;
   opacity: 0.35;
 }

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-color: grey;
  opacity: 0.35;
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: grey;
  opacity: 0.35;
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  background-color: grey;
  opacity: 0.35;
}
